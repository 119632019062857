/* eslint-disable import/extensions */
import {
  appraisalDashboardModule,
  webCommonService,
  webBorrowerPortalService,
  budgetReviewModule,
  webLoanService,
  webDashboardService
} from "./mfe-remotes-config/remoteConfig.js";

window[webDashboardService.urlGlobalVariable] = webDashboardService.url;
window[webCommonService.urlGlobalVariable] = webCommonService.url;
window[webLoanService.urlGlobalVariable] = webLoanService.url;
window[appraisalDashboardModule.urlGlobalVariable] =
  appraisalDashboardModule.url;
window[webBorrowerPortalService.urlGlobalVariable] =
  webBorrowerPortalService.url;
window[budgetReviewModule.urlGlobalVariable] = budgetReviewModule.url;

import("./bootstrap.jsx");
