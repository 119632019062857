const webCommonService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_COMMON_SERVICE",
  port: 3001,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3001/webcommon`
      : `${window.location.origin}/webcommon`;
  },
  urlGlobalVariable: "OP_WEB_COMMON_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const webLoanService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_LOAN_SERVICE",
  port: 3001,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3002/webloan`
      : `${window.location.origin}/webloan`;
  },
  urlGlobalVariable: "OP_WEB_LOAN_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const webBorrowerPortalService = {
  fileName: "remoteEntry.js",
  name: "webborrowerportal",
  port: 3005,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3005/webborrowerportal`
      : `${window.location.origin}/webborrowerportal`;
  },
  urlGlobalVariable: "webborrowerportalUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const appraisalDashboardModule = {
  fileName: "remoteEntry.js",
  name: "appraisal_dashboard_module",
  port: 3004,
  get url() {
    return window.location.protocol === "http:"
      ? `https://dev.merchantsmtg.info/appraisal_dashboard`
      : `${window.location.origin}/appraisal_dashboard`;
  },
  urlGlobalVariable: "appraisal_dashboard_moduleUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const webAdminService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_ADMIN_SERVICE",
  port: 3007,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3007/webadmin`
      : `${window.location.origin}/webadmin`;
  },
  urlGlobalVariable: "OP_WEB_ADMIN_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};
const budgetReviewModule = {
  fileName: "remoteEntry.js",
  name: "toorakBudgetReview",
  port: 3008,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3008/budget_review_module`
      : `${window.location.origin}/budget_review_module`;
  },
  urlGlobalVariable: "toorakBudgetReviewUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

const webDashboardService = {
  fileName: "remoteEntry.js",
  name: "OP_WEB_DASHBOARD_SERVICE",
  port: 3009,
  get url() {
    return window.location.protocol === "http:"
      ? `http://${window.location.hostname}:3009/webdashboard`
      : `${window.location.origin}/webdashboard`;
  },
  urlGlobalVariable: "OP_WEB_DASHBOARD_SERVICEUrl",
  get federationConfig() {
    return `${this.name}@[window.${this.urlGlobalVariable}]/${this.fileName}`;
  }
};

module.exports = {
  webCommonService,
  webLoanService,
  webAdminService,
  appraisalDashboardModule,

  webBorrowerPortalService,
  budgetReviewModule,
  webDashboardService
};
